.svg-icon svg {
    position: relative;
    height  : 1.5em;
    width   : 1.5em;
}

.svg-text svg {
    stroke: #424242;
}

.svg-180 svg {
    transform: rotate(180deg);
}

.form-input {
    padding         : 0.375rem;
    background-color: #eeeeee;
    border          : none;
    border-radius   : 4px;
    font-size       : 0.875rem;
    color           : #424242;
}

.form-input:focus {
    outline   : none;
    box-shadow: 0 0 1px 2px #8ecae6;
}

.is-fullwidth {
    width: 100%;
}

.bg-white {
    background-color: white;
}

.data-input {
    font-weight     : 400;
    background-color: green;
    white-space     : pre-wrap;
    padding         : 0.5rem;
    color           : #050505;
    font-size       : 1.2rem;
    resize          : none;
    flex            : 1 1 auto;
}

.td:last-child .data-input{
    background-color: orange !important;
    pointer-events  : none !important;
    cursor: not-allowed !important;
}

.shadow-5 {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.12),
        0 8px 16px rgba(0, 0, 0, 0.12), 0 16px 32px rgba(0, 0, 0, 0.12);
}

.svg-icon-sm svg {
    position: relative;
    height  : 1em;
    width   : 1em;
}

.svg-gray svg {
    stroke: #9e9e9e;
}

.option-input {
    width           : 100%;
    font-size       : 1rem;
    border          : none;
    background-color: transparent;
}

.option-input:focus {
    outline: none;
}

.overlay {
    position: fixed;
    top     : 0;
    left    : 0;
    height  : 100vh;
    width   : 100vw;
    z-index : 2;
    overflow: hidden;
}

/* .sort-button {
    padding         : 0.25rem 0.75rem;
    width           : 100%;
    background-color: transparent;
    border          : 0;
    font-size       : 0.875rem;
    color           : #757575;
    cursor          : pointer;
    text-align      : left;
    display         : flex;
    align-items     : center;
}

.sort-button:hover {
    background-color: #eeeeee;
} */

.add-row {
    color      : #9e9e9e;
    padding    : 0.5rem;
    display    : flex;
    align-items: center;
    font-size  : 0.875rem;
    cursor     : pointer;
}

.add-row:hover {
    background-color: #f5f5f5;
}

.th {
    color      : #000000;
    font-weight: 700;
    font-size  : 1.2rem;
    cursor     : pointer;
    width      : 33%;
}

.th-content {
    overflow-x   : hidden;
    text-overflow: ellipsis;
    padding      : 0.5rem;
    display      : flex;
    align-items  : center;
}

.td {
    overflow   : hidden;
    color      : #000000;
    align-items: stretch;
    padding    : 0;
    display    : flex;
    width      : 33% !important;
}

.td-content {
    display: block;
}

.table {
    white-space   : nowrap;
    margin-top    : 3%;
    border-spacing: 0;
    border        : 1px solid #e0e0e0;
    border-radius : 5px;
}

.th:last-child {
    width: 34% !important;
}

.td:last-child {
    width: 34% !important;
}

.th,
.td {
    margin  : 0;
    border  : 1px solid #e0e0e0;
    position: relative;
}

.text-align-right {
    text-align: right;
}

.cell-padding {
    padding: 0.5rem;
}

.d-flex {
    display: flex;
}

.cursor-default {
    cursor: default;
}

.align-items-center {
    align-items: center;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.border-radius-md {
    border-radius: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-margin {
    margin-right: 4px;
}

.font-weight-600 {
    font-weight: 600;
}

.font-size-75 {
    font-size: 0.75rem;
}

.flex-1 {
    flex: 1;
}

.w-69 {
    width: 69% !important;
}

.input-human {
    background-color: green;
}

.input-logic {
    background-color: orange;
    pointer-events  : none;
}